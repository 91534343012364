const ADDRESS =  'https://stocks.ges.services'
//const ADDRESS =   'http://localhost:3000'  
//const ADDRESS =  'https://beta.ges.services' 
const ROLES_GESTOR = [30,31,41,50,51]
const ROLES_IGES_PRICING = [7,109,112,92,86,118]
const debug = false;

import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';
import eventBus from "@/event-bus";
import Vuelidate from 'vuelidate'

import {globalFilters} from './utilities/filters'
import {setInitDates, getInitFilters} from '@/utilities/services/init_services'
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Chips from 'primevue/chips';
import Paginator from 'primevue/paginator';
import Calendar from 'primevue/calendar';

import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import MultiSelect from 'primevue/multiselect';
import Button from 'primevue/button';
import DropDown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';



/** Wijmo components */
import { registerGrid } from '@grapecity/wijmo.vue2.grid';
import { registerGridFilter } from '@grapecity/wijmo.vue2.grid.filter';
import { registerInput } from "@grapecity/wijmo.vue2.input";
import { registerCore } from '@grapecity/wijmo.vue2.core';
//import  { registerGridXlsx } from '@grapecity/wijmo.grid.xlsx';

 // apply Wijmo license key (if you have one)  
import { setLicenseKey } from '@grapecity//wijmo';  
setLicenseKey('stocks.ges.digital|proyectos.ges.digital|beta.ges.digital,288251898349353#B0cJMLiEjdyIDMyIiOiIXZ6JCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBxUS5c4SGNzKulVc7V6YuFVcalFaCJlSx54RhNUd5RHWQhnRBN6KHZmVSBlMa56Y0ZGWpRTZ6hWeS5UNwQjTwtiTPVGStJlNwEmVOR6V7pXMnJUaZJFem94TRxGS9ckT9F4aX5mWjJmQtNmToljdwx6S5cFewJzcLxGb446T92EdUVmWx8kN9p7T4VVeMJzKz9WW6tkWnlzdPxkaXdFc5h5Qhd5LuFkZ0JEVhdlTIJXcWh6NMN5UsVGamB5YPdlSIRkdnFDa7gka5BDU9llY7oXTv8kT6pWYLVUc7MTOk9EOrB5MrEkZqlVd7dlVoNXZYdWThRDUshVS8NTQWNEb5VkStVFO4MEcRVWYHBleT9GbvhVdzIDVSx6aNdUSmhTNZpmMjNFUIdXRr5EU7d7Zup7SKhXdodDM7FTU6dlW4ATVYJ6YTh6TFJUY8VTaOtGVNdHVn5mTiojITJCLiQTQBNURENUMiojIIJCLzcTO6MTNxIjM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsICNyITNyADIxAjMwIjMwIjI0ICdyNkIsICbhRXanlGZuMXZn9SY4VmYswWY4l6ZpRmLzV6ZuM7b4NWZ93mcwxCbhRXanlGZuMXZn9ycrN6b4NnI0IyctRkIsIycrN6b4NFIvJHdjVGbFBybwVncHJiOiEmTDJCLiMTNzkDNzgTO8ETNygDOyIiOiQWSiwSfdtlOgcIb');  
import '@grapecity/wijmo.cultures/wijmo.culture.es';

import "@grapecity/wijmo.styles/wijmo.css";
//import '@grapecity/wijmo.styles/themes/wijmo.theme.light.css';
//import '@grapecity/wijmo.styles/themes/wijmo.theme.material.css';
//import '@grapecity/wijmo.styles/themes/wijmo.theme.material.indigo-orange.css';
import '@grapecity/wijmo.styles/themes/wijmo.theme.material.css';
// Iconos alternativos para wijmo
import 'font-awesome/css/font-awesome.css'



/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/** Importamos los css de primevue */
import 'primevue/resources/themes/mdc-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';


/* Theme variables */
import './theme/variables.css';
import './registerServiceWorker'

/** Customizaciones datatable -han de ser globales- */

window.miconsola =   debug===false ?  function() {void(0)} : console.log
window.fake_console =  debug===false ?  function() {void(0)} : console.log


/** 
 * Eliminamos la cache de memoria,
 * Generaba problemas cuando cambiabamos de usuarios 
 * porque retenia las petitiones en memoria 
 
//import { setupCache } from 'axios-cache-adapter'
axios.defaults.adapter = cache.adapter 
const cache = setupCache(
    {
       maxAge: 10 * 60 * 1000 , 
       exclude : {
         paths: ['/check']
       }
    })
  */

/** VARIABLES GLOBALS DEL PROYECTO, CARGAN UNA ÚNICA VEZ EN MEMORIA */

axios.defaults.baseURL = ADDRESS

axios.defaults.headers["Content-Type"] = "application/json"
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers["Access-Control-Allow-Origin"] = "*"
axios.mode = "no-cors"

/** FB config*/
firebase.initializeApp({
  apiKey: "AIzaSyAzwMsPdcrIbVEvqrqopkjpOLG2Vm3PjGA",
  authDomain: "appgescom.firebaseapp.com",
  projectId: "appgescom",
  storageBucket: "appgescom.appspot.com",
  messagingSenderId: "728244704694",
  appId: "1:728244704694:web:ced2e56799f4abb42a1d17",
  measurementId: "G-KX4GNXBZ0Q",
  databaseURL: "https://appgescom.firebaseio.com"
});

/** 
 * Logica de la aplicación 
 * El router no se inicializa hasta que tenemos 
 * un cambio de AuthState en firebase
 * Sino monta las paginas antes de tener el usuario 
 * */

let app = createApp(App)
          .use(IonicVue)
          .use(PrimeVue)
          .use(Vuelidate)
          .use(router);

app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('Row', Row);

app.component('InputText',InputText);
app.component('InputNumber',InputNumber);
app.component('MultiSelect',MultiSelect);
app.component('Button',Button);
app.component('DropDown',DropDown);
app.component('Chips',Chips);
app.component('Paginator',Paginator);
app.component('Calendar',Calendar);
app.component('FileUpload',FileUpload);

//app.component('Chart',Chart);

/** Wijmo grid */
registerGrid(app);
registerGridFilter(app)
registerInput(app)
registerCore(app)
//registerGridXlsx(app)


/**
 * Variables globales 
 */
var self = app.config.globalProperties
self.$http = axios
self.$current_user = undefined
self.$current_user_name = undefined
self.$device = undefined
self.$filters = globalFilters
self.$ges = undefined 
self.$multi = undefined
self.$pdv_root = undefined
self.$utype = undefined
self.$fechas = undefined
self.$deeplink = undefined 
self.$compras = undefined
self.$pricing = undefined 


/**
 * Variable global para acceder a Firebase 
 * desde el conjunto de la aplicación 
 * sino acaba intentando instanciarla varias veces
 */
self.$firebase = firebase
window.fake_console('main ','RUTAS : '+JSON.stringify(self.$router.routes))
/** Logica de la aplicación */
firebase.auth().onAuthStateChanged(async (usr) => {
   /** Aqui el nombre del usuario esta a primer nivel, 
    *  Firebase devuelve el objetvo 'user' 
    */
   window.fake_console('main ',usr)
   window.fake_console('main ','paso por la función onAuthStateChanged')
   if(usr!=null) {
    /** Hay usuario, obtenemos token y fijamos rol y datos del usuario  */
    window.fake_console('main ','if/main 110: '+usr.displayName)
    firebase.auth().currentUser.getIdToken(true).then(async (idToken) =>{
      window.fake_console('en el auth')
      //window.fake_consoleidToken)
      self.$http.defaults.headers['Authorization'] = 'Bearer '+idToken
      self.$fechas = await setInitDates()
      await getInitFilters()
      const supRequest = await self.$http.get('/check')
      const roles = supRequest.data
      

      roles['who'] = usr.displayName
      window.fake_console('main ',roles) 
      //eventBus().emitter.emit('user-logged',{'who':usr.displayName, 'uname': roles.uname, 'ges': roles.ges, 'multi': roles.multi, 'pdv_root':roles.pdv_root});   
      eventBus().emitter.emit('user-logged',roles);   
      window.fake_console('main ','RUTAS 133: '+JSON.stringify(self.$router.routes))
      //self.$router.replace('/resumen/sufficiency') 
      self.$router.replace(self.$deeplink != undefined ? self.$deeplink : "/gestor/list"); 
    })
   }
   else {
     /** no hay usuario, pasamos por login */
     window.fake_console('main ','No hay usuario, pasamos por pagina de login')
     self.$router.replace('/login')
   }
});

router.isReady().then(() => {
  window.fake_console('main ','App montada')
  app.mount('#app');
  
});



eventBus().emitter.on('device',(dev)=>{ self.$device=dev});
// Control de deeplinks 
eventBus().emitter.on('deeplink',(dl)=>{ 
  window.fake_console('en el evento '+dl)
  self.$deeplink=dl
});
/** Variables globales que se utilizan en el comportamiento de la app */
eventBus().emitter.on('user-logged',(roles)=>{ 
  self.$current_user = roles.usr
  self.$current_user_name = roles.uname
  self.$ges = roles.ges
  self.$compras = ROLES_GESTOR.indexOf(roles.rol)!=-1
  self.$pricing = ROLES_IGES_PRICING.indexOf(roles.iges)!=-1
  self.$multi = roles.multi === true ? 'multi' : 'single'
  self.$pdv_root = roles.pdv_root
  self.$utype = roles.tp
  self.$mispdvs = roles.pdvs 

  window.fake_console('main ',roles)
});
